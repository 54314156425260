import {
  FETCH_DIUOBYDOSS_BEGIN,
  FETCH_DIUOBYDOSS_SUCCESS,
  FETCH_DIUOBYDOSS_FAILURE,
  CHANGE_INDEX_VALUE
} from '../actions/diuoActions';

const initialState = {
  diuoByDoss: [],
  diuoValue: '',
  index: '',
  loading: false,
  error: null
}

export default function diuoReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_DIUOBYDOSS_BEGIN: {
      return {
        ...state,
        loading: true,
        error: null
      }
    }
    case FETCH_DIUOBYDOSS_SUCCESS: {
      return {
        ...state,
        loading: false,
        diuoByDoss: action.payload.diuoByDoss,
        index: action.payload.diuoByDoss.length > 0 ? action.payload.diuoByDoss[action.payload.diuoByDoss.length - 1].diuo_index : '',
        diuoValue: action.payload.diuoByDoss.length > 0 ? action.payload.diuoByDoss[action.payload.diuoByDoss.length - 1].id : ''
      }
    }
    case FETCH_DIUOBYDOSS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        diuoByDoss: []
      };
    }
    case CHANGE_INDEX_VALUE: {
      let newDiuoValue = '';
      state.diuoByDoss.forEach(diuo => {
        if (diuo.diuo_index === action.payload.index) {
          newDiuoValue = diuo.id;
        }
      });
      return {
        ...state,
        index: action.payload.index,
        diuoValue: newDiuoValue
      };
    }
    default:
      return state
  }
}