import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import Login from "../LoginPage/Login.js";
import Register from "../LoginPage/Register.js";
import Dashboard from "../Dashboard/Dashboard.js";
import Accueil from "../Accueil.js";
import FactureCon from "../Factures/FactureCon";
import FactureRea from "../Factures/FactureRea";
import FactureTable from "../Factures/FactureTable";
import Reminder from "../Factures/Reminder";
import FactureRecap from "../Factures/FactureRecap.js";
import Global from "../Factures/Global.js";
import ContractAmendment from "../FactureAmendment/ContractAmendment.js";
import FacAmendment from "../FactureAmendment/FacAmendment.js";
import FacAmendmentRecap from "../FactureAmendment/FacAmendmentRecap.js";
import FacAmendmentReminder from "../FactureAmendment/FacAmendmentReminder.js";
import FacAmendmentTable from "../FactureAmendment/FacAmendmentTable.js";
import FacAmendmentGlobal from "../FactureAmendment/FacAmendmentGlobal.js";
import history from "./history";
import CreateMail from "../Mail/CreateMail";
import GererNotif from "../GererNotif.js";
import detailNotif from "../detailNotif.js";
import DevisPropostion from "../Devis/DevisProposition.js";
import DevisValidation from "../Devis/DevisValidation/DevisValidation";
import DevisRea from "../Devis/DevisRea/DevisRea.js";
import DevisCon from "../Devis/DevisCon/DevisCon.js";
import CalcDevis from "../Devis/CalcDevis/CalcDevis.js";
import CreaDossier from "../Devis/CreaDossier/CreaDossier.js";
import ContratData from "../Contrat/ContratData.js";
import ContratEch from "../Contrat/ContratEch/ContratEch.js";
import ContratValid from "../Contrat/ContratValid/ContratValid.js";
import Confirm from "../LoginPage/Confirm.js";
import ForgotPass from "../LoginPage/ForgotPass.js";
import Chat from "../Dashboard/Chat";
import Chatbott from "../Dashboard/Chatbot";
import Notifications from "../Notifications";
import QuotaH from "../Heures/Quota/QuotaH";
import Hist from "../Heures/Historique/Hist.js";
import Htp from "../Heures/HTP/Htp.js";
import Agenda from "../Calendar/Agenda/Agenda.js";
import Appointment from "../Calendar/Appointment/Appointment.js";
import Schedule from "../Calendar/Schedule/Schedule.js";
import Page1 from "../VIC/Page1/Page1.js";
import Page2 from "../VIC/Page2/Page2.js";
import Page3 from "../VIC/Page3/Page3.js";
import Page4 from "../VIC/Page4/Page4.js";
import Page5 from "../VIC/Page5/Page5.js";
import Page6 from "../VIC/Page6/Page6.js";
import Page7 from "../VIC/Page7/Page7.js";
import Page8 from "../VIC/Page8/Page8.js";
import VICValidation from "../VIC/VICValidation.js";
import PgcPage1 from "../PGC/Page1/Page1.js";
import PgcPage2 from "../PGC/Page2/Page2.js";
import PgcPage3 from "../PGC/Page3/Page3.js";
import PgcPage4 from "../PGC/Page4/Page4.js";
import PgcPage5 from "../PGC/Page5/Page5.js";
import PgcPage6 from "../PGC/Page6/Page6.js";
import PgcPage7 from "../PGC/Page7/Page7.js";
import PreDiuoPage1 from "../PreDIUO/Page1/Page1.js";
import PreDiuoPage2 from "../PreDIUO/Page2/Page2.js";
import PreDiuoPage3 from "../PreDIUO/Page3/Page3.js";
import PreDiuoPage4 from "../PreDIUO/Page4/Page4.js";
import DiuoPage1 from "../DIUO/Page1/Page1.js";
import DiuoPage2 from "../DIUO/Page2/Page2.js";
import DiuoPage3 from "../DIUO/Page3/Page3.js";
import DiuoPage4 from "../DIUO/Page4/Page4.js";
import DiuoPage5 from "../DIUO/Page5/Page5.js";
import DiuoPage6 from "../DIUO/Page6/Page6.js";
import DiuoPage7 from "../DIUO/Page7/Page7.js";
import Page_1 from "../VIC/Pages/Page1/Page1.js";
import Page_2 from "../VIC/Pages/Page2/Page2.js";
import Page_3 from "../VIC/Pages/Page3/Page3.js";
import Page_4 from "../VIC/Pages/Page4/Page4.js";
import Page_5 from "../VIC/Pages/Page5/Page5.js";
import Page_6 from "../VIC/Pages/Page6/Page6.js";
import Page_7 from "../VIC/Pages/Page7/Page7.js";
import Page_8 from "../VIC/Pages/Page8/Page8.js";
import VicValidations from "../VIC/Pages/VicValidations/VicValidations.js";
import EmploymentLaw from "../VIC/EmploymentLaw.js";
import EvaluationRisque from "../RJ/EvaluationRisque/EvaluationRisque.js";
import Observations from "../RJ/Observations/Observations.js";
import Contacts from "../RJ/Contacts/Contacts.js";
import SuiviEffectifs from "../RJ/SuiviEffectifs/SuiviEffectifs";
import RJValidation from "../RJ/RJValidation";
import RjEmploymentLaw from "../RJ/RjEmploymentLaw";
class Routes extends React.Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Accueil} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/login" component={Login} />
          <Route path="/forgotpass" component={ForgotPass} />
          <Route path="/changepassword/:crypted" component={ForgotPass} />
          <Route path="/Chat" component={Chat} />
          <Route path="/chatbott" component={Chatbott} />
          <Route path="/notifications/:idnotif" component={Notifications} />
          <Route path="/register" component={Register} />
          <Route path="/confirm/:confirmationCode" component={Confirm} />
          <Route path="/notifications" component={GererNotif} />
          <Route path="/:doc/mail" component={CreateMail} />
          <Route path="/facture/conception" component={FactureCon} />
          <Route path="/facture/realisation" component={FactureRea} />
          <Route path="/facture/recapitulative" component={FactureRecap} />
          <Route path="/facture/global" component={Global} />
          <Route path="/facture/liste" component={FactureTable} />
          <Route path="/facture/relance" component={Reminder} />
          <Route path="/avenant/contrat" component={ContractAmendment} />
          <Route path="/avenant/facture" component={FacAmendment} />
          <Route path="/avenant/facture_recap" component={FacAmendmentRecap} />
          <Route path="/avenant/facture_relance" component={FacAmendmentReminder} />
          <Route path="/avenant/tableau_facture" component={FacAmendmentTable} />
          <Route path="/avenant/synthese_facture" component={FacAmendmentGlobal} />
          <Route path="/detail" component={detailNotif} />
          <Route path="/devis/calcul" component={CalcDevis} />
          <Route path="/devis/conception" component={DevisCon} />
          <Route path="/devis/realisation" component={DevisRea} />
          <Route path="/:doc/proposition" component={DevisPropostion} />
          <Route path="/devis/validation" component={DevisValidation} />
          <Route path="/devis/dossier" component={CreaDossier} />
          <Route path="/contrat/données" component={ContratData} />
          <Route path="/contrat/echeances" component={ContratEch} />
          <Route path="/contrat/validation" component={ContratValid} />
          <Route path="/heures/quota" component={QuotaH} />
          <Route path="/heures/historique" component={Hist} />
          <Route path="/heures/htp" component={Htp} />
          <Route path="/calendrier/agenda" component={Agenda} />
          <Route path="/calendrier/rdv" component={Appointment} />
          <Route path="/calendrier/planning" component={Schedule} />
          <Route path="/pgc/page1" component={PgcPage1} />
          <Route path="/pgc/page2" component={PgcPage2} />
          <Route path="/pgc/page3" component={PgcPage3} />
          <Route path="/pgc/page4" component={PgcPage4} />
          <Route path="/pgc/page5" component={PgcPage5} />
          <Route path="/pgc/page6" component={PgcPage6} />
          <Route path="/pgc/validation" component={PgcPage7} />
          <Route path="/prediuo/page1" component={PreDiuoPage1} />
          <Route path="/prediuo/page2" component={PreDiuoPage2} />
          <Route path="/prediuo/page3" component={PreDiuoPage3} />
          <Route path="/prediuo/validation" component={PreDiuoPage4} />
          <Route path="/diuo/page1" component={DiuoPage1} />
          <Route path="/diuo/page2" component={DiuoPage2} />
          <Route path="/diuo/page3" component={DiuoPage3} />
          <Route path="/diuo/page4" component={DiuoPage4} />
          <Route path="/diuo/page5" component={DiuoPage5} />
          <Route path="/diuo/page6" component={DiuoPage6} />
          <Route path="/diuo/page7" component={DiuoPage7} />
          <Route path="/vic/page1" component={Page1} />
          <Route path="/vic/page-1" component={Page_1} />
          <Route path="/vic/Page2" component={Page2} />
          <Route path="/vic/Page-2" component={Page_2} />
          <Route path="/vic/page3" component={Page3} />
          <Route path="/vic/Page-3" component={Page_3} />
          <Route path="/vic/Page4" component={Page4} />
          <Route path="/vic/Page-4" component={Page_4} />
          <Route path="/vic/page5" component={Page5} />
          <Route path="/vic/Page-5" component={Page_5} />
          <Route path="/vic/Page6" component={Page6} />
          <Route path="/vic/Page-6" component={Page_6} />
          <Route path="/vic/page7" component={Page7} />
          <Route path="/vic/page-7" component={Page_7} />
          <Route path="/vic/Page8" component={Page8} />
          <Route path="/vic/page-8" component={Page_8} />
          <Route path="/vic/validation" component={VICValidation} />
          <Route path="/vic/validations" component={VicValidations} />
          <Route path="/vic/code-travail" component={EmploymentLaw} />
          <Route path="/rj/evaluation" component={EvaluationRisque} />
          <Route path="/rj/observations" component={Observations} />
          <Route path="/rj/contacts" component={Contacts} />
          <Route path="/rj/suivi-effectifs" component={SuiviEffectifs} />
          <Route path="/rj/validation" component={RJValidation} />
          <Route path="/rj/code-travail" component={RjEmploymentLaw} />
        </Switch>
      </Router>
    );
  }
}
export default Routes;
