import React from 'react';
import '../Styles/LoadingOverlay.scss';

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="spinner-container">
        <div className="spinner"></div>
        <div className="loading-text">Chargement en cours...</div>
      </div>
    </div>
  );
};

export default LoadingOverlay;