import { Button } from '@material-ui/core';
import React from 'react';
import '../../../Styles/Devis/CreaDossier.scss';
import Header from '../../Header/Header';
import { connect } from 'react-redux';
import { fetchDevis } from "../../../redux/actions/devisActions";
import { fetchContact } from '../../../redux/actions/contactActions';
import { Slider } from '@material-ui/core';
import ConfirmDoss from './Dialogs/ConfirmDoss';
import SuppDialog from './Dialogs/SuppDialog';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CompMo from './CompMo';
import { fetchDossier } from '../../../redux/actions/dossierActions';
import getEntrepriseFromName from "../../Utils"
import { fetchEntreprise } from "../../../redux/actions/entrepriseActions"

class CreaDossier extends React.Component {
constructor(props){
    super(props)
    this.state={
        numDoss:"",
        op:"",
        addrop:"",
        villeop:"",
        cpop:"",
        catop:"1",
        coutop:"",
        domaine:"Public",
        duréeop:0,
        company:"",
        mo:"",
        sexe:"",
        idmo:"",
        adress:"",
        city:"",
        cp:"",
        tel_port:"",
        mail:"",
        nomDoss:"DOSSIER DEVIS N° ",
        disableButton:false,
        creaDialog:false,
        suppDialog:false,
        index:0,
        isCrea:false,
        openDialog: false,
        unauthorized: "",
    }
    this.handleChange=this.handleChange.bind(this)
    this.setOpenCrea=this.setOpenCrea.bind(this)
    this.setOpenSupp=this.setOpenSupp.bind(this)
    this.setCompany=this.setCompany.bind(this)
    this.setMo=this.setMo.bind(this)
    this.handleChangeMoInfos=this.handleChangeMoInfos.bind(this)
}
/**
 * Initialise le nom du dossier avec le dernier numéro de dossier +1
 */
getNumDoss(){
  fetch("/api/devis/getNumDoss")
  .then(res => res.json())
  .then(res => this.setState({numDoss:Number(res[0].max_ndossier)+1}, () => {
    this.setState({nomDoss: "DOSSIER DEVIS N° "+this.state.numDoss})
  }))
}
/**
 * Initialise les données afin d'obtenir le dernier numéro de dossier et la liste des contacts de la base de données
 */
async componentDidMount(){
  if(this.props.deviscon.length===0 || this.props.deviscon===undefined){
    await this.props.dispatch(fetchDevis())
    await this.props.dispatch(fetchContact())
  }
  this.getNumDoss()
  this.props.dispatch(fetchEntreprise());
}

/**
 * Change le nom du dossier en fonction des données du dossier
 * @param {{}} event 
 */
handleChange(event){
  const regex = /[\\/*^?"&@#ç!§(°%$£;:€)={}\[\]]/g;
  const value = event.target.value;
  if (regex.test(value)) {
    this.setState({
      unauthorized: value.charAt(value.length - 1),
      openDialog: true
    })
    return;
  }
  if (
    getEntrepriseFromName(value, this.props.entreprise).id ===
    undefined
  ){
    this.handleChangeMoInfos(
      true,
      "",
      "",
      "",
      "",
      "",
      "homme"
    );
    this.setState({idmo: ""})
  }
  this.setState({[event.target.name]:value}, () =>{
    this.setState({nomDoss: "DOSSIER DEVIS N° "+this.state.numDoss+" "+this.state.op+" "+this.state.mo+" "+this.state.villeop+" "+this.state.company})
  })
}

handleChangeNumDoss = (event) => {
  const regex = /^\d+$/;
  let value = event.target.value;
  
  if (event.target.name === "numDoss") {
    if (value === "" || regex.test(value)) {
      this.setState((prevState) => ({
        numDoss: value,
        nomDoss: "DOSSIER DEVIS N° " + value + " " + prevState.op + " " + prevState.mo + " " + prevState.villeop + " " + prevState.company
      }));
    }
  }
}

/**
 * Permet à l'utilisateur de ne rentrer que des chiffres, nombres ainsi que des décimaux
 * @param {{}} event 
 */
handleCoutChange(event){
  if(/^[0-9][\.\d]*$/.test(event.target.value) || event.target.value==""){
      this.setState({[event.target.name]:event.target.value})
  }
}

/**
 * Permet de changer la valeur de la durée de l'opération via un Slider
 * @param {{}} event 
 * @param {number} newValue 
 */
handleSliderChange(event){
  const value = event.target.value === "" || isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value);
  this.setState({duréeop: value})
}

/**
 * Envoie les données du dossier au Back
 */
async handleClickDossier(){
  this.setState({disableButton: true})
  const json = {}
  for(let i=0;i<Object.keys(this.state).length;i++){
    json[Object.keys(this.state)[i]] = Object.values(this.state)[i]
  }
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
    body: JSON.stringify(json)
    };
    let resp1 = await fetch("/api/entreprise/verifDossier",requestOptions)
    .catch((err) => console.log(err))
    if(resp1.ok){
      let resp2 = await fetch("/api/contact/verifDossier",requestOptions)
      .catch((err) => console.log(err))
      if(resp2.ok){
        await fetch("/api/devis/createDossier",requestOptions)
        .then(res => {
          if(res.status===200){
            this.setState({numDoss:this.state.numDoss+1}, () => {
              this.setState({nomDoss: "DOSSIER DEVIS N° "+this.state.numDoss})
            })
            this.setState({op:"", addrop:"", villeop:"", cpop:"", coutop:"", duréeop:0, domaine:"Public", catop:"1",adress:"", city:"", cp:"",mo:"", tel_port:"",mail:"", sexe:"homme",company:""})
          } 
        })
        .catch((err) => console.log(err))
      }
    }
   await this.getNumDoss()
   await this.props.dispatch(fetchDevis())
   await this.props.dispatch(fetchDossier())
   await this.props.dispatch(fetchContact())
   await this.setState({disableButton: false})
}

/**
 * Change la valeur de l'état "company" ainsi que "nomDoss"
 * @param {string} value 
 */
setCompany(value){
  this.setState({company:value}, () =>{
    this.setState({nomDoss: "DOSSIER DEVIS N° "+this.state.numDoss+" "+this.state.op+" "+this.state.mo+" "+this.state.villeop+" "+this.state.company})
  })
}

/**
 * Change la valeur de l'état "mo"
 * @param {* string} value 
 */
setMo(value,id){
  this.setState({mo:value,idmo:id})
}

handleChangeMoInfos(event,adress,city,cp,tel_port,mail,sexe){
  if(event.target){
    this.setState({[event.target.name]:event.target.value})
  } else {
    this.setState({adress,city,cp,tel_port,mail,sexe})
  }
}

/**
 * Supprime un dossier de la liste
 *
 */
 async handleSuppDoss(){
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json','Accept': 'application/json' },
    body: JSON.stringify({numDoss:this.props.dossierTab[this.state.index].ndossier})
    }; 
  await fetch("/api/devis/delete",requestOptions)
  await this.getNumDoss()
  await this.props.dispatch(fetchDevis())
  await this.props.dispatch(fetchDossier())
}

/**
 * Affiche le dialogue de création d"un dossier et le fait disparaître en fonction de "isOpen"
 * @param {boolean} isOpen 
 */
setOpenCrea(isOpen){
  this.setState({creaDialog:isOpen})
}

/**
 * Affiche le dialogue de suppréssion d'un dossier en fonction de l'index de celui-ci et le fait disparaître en fonction de "isOpen"
 * @param {boolean} isOpen 
 * @param {number} index 
 */
setOpenSupp(isOpen,index){
  this.setState({suppDialog:isOpen},() =>{
    this.setState({index:index})
  })
}

handleClose = () => {
  this.setState({ openDialog: false });
};
    render() {
      const inputProps = {
        name:"mo",
        value: this.state.mo,
        onChange: this.handleChange
      };
      return (
      <div className="creaDossier">
          <Header/>
          <h1 className="title">Création d'un dossier</h1>
          <div className="cadreDoss">
            <div className="formDoss">
              <div className="opInfos">
                  <div><label>Numéro de dossier : </label><input value={this.state.numDoss} name="numDoss" onChange={this.handleChangeNumDoss}/></div>
                  <div><label>Opération : </label><input value={this.state.op} name="op" onChange={this.handleChange}/></div>
                  <div><label>Adresse Opération : </label><input value={this.state.addrop} name="addrop" onChange={this.handleChange}/></div>
                  <div><label>Ville : </label><input value={this.state.villeop} name="villeop" onChange={this.handleChange}/></div>
                  <div><label>Code Postal : </label><input value={this.state.cpop} name="cpop" onChange={this.handleChange}/></div>
                  <div><label>Coût : </label><input value={this.state.coutop} name="coutop" onChange={this.handleCoutChange.bind(this)}/><label>(en millions €)</label></div>
              </div>
              <div className="mouInfos">
                <div><label>Domaine : </label>
                    <select name="domaine" onChange={this.handleChange}>
                      <option value="public">Public</option>
                      <option value="privé">Privé</option>
                    </select>
                </div>
                <div><label>Catégorie : </label>
                    <select name="catop" onChange={this.handleChange}>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                </div>
                <div className='durée'><label>Durée : </label>
                  <Slider value={this.state.duréeop} step={0.5}  min={0} max={48} onChange={(_, value) => this.setState({ duréeop: value })}/>
                  <div>
                    <input value={this.state.duréeop} style={{width:"30px"}} onChange={this.handleSliderChange.bind(this)}/>
                    <label> Mois</label>
                  </div>
                </div>
                <CompMo inputProps={inputProps} handleChange={this.handleChange} company={this.state.company} 
                setCompany={this.setCompany} setMo={this.setMo} handleChangeMoInfos={this.handleChangeMoInfos} adress={this.state.adress} city={this.state.city} cp={this.state.cp} tel_port={this.state.tel_port} mail={this.state.mail} sexe={this.state.sexe}/>
              </div>
          </div>
          <div><label>{this.state.nomDoss}</label></div>
          <Button variant="contained" color="primary" onClick={() => this.setOpenCrea(true)} disabled={this.state.disableButton}>Créer un dossier</Button>
          {/* <Button variant="contained" color="primary" onClick={this.handleClickDossier.bind(this)}>Créer un dossier</Button> */}
          </div>
          <div className="listeDoss">
            {this.props.dossierTab.map((doss,i) =>
              <div key={i}><label>Dossier N° {doss.ndossier}</label><Button variant="contained" onClick={() => this.setOpenSupp(true,i)}>Supprimer</Button></div>
            )}
          </div>
          <Dialog open={this.state.openDialog} onClose={this.handleClose}>
            <DialogTitle>INFORMATION</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Le caractère que vous avez saisi '{this.state.unauthorized}' n'est pas autorisé !
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={this.handleClose} style={{color: "white", backgroundColor: "#FF7F50"}}>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <ConfirmDoss open={this.state.creaDialog}
           setOpen={this.setOpenCrea}
          onConfirm={this.handleClickDossier.bind(this)}/>
          <SuppDialog open={this.state.suppDialog}
           setOpen={this.setOpenSupp}
          onConfirm={this.handleSuppDoss.bind(this)}
         index={this.state.index}/>
      </div>         
      )

    }


  }
  const mapStateToProps = state => ({
    devisrea : state.devis.devisrea,
    deviscon : state.devis.deviscon,
    dossierValue : state.devis.dossierValue,
    dossierTab: state.devis.dossierTab,
    contact : state.contact.items,
    entreprise: state.entreprise.items,
  });
export default connect(mapStateToProps)(CreaDossier)