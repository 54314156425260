export const getPgcCoverPageData = async (ndossier) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getLastCoverPageDataByNdoss/${encodeURIComponent(ndossier)}`,
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `La requête a échoué avec le statut ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données de la page de couverture:', error);
    throw error;
  }
};

export const getCoverPageData = async (diuoValue) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getCoverPageData/${encodeURIComponent(diuoValue)}`,
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || `La requête a échoué avec le statut ${response.status}`);
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données de la page de couverture:', error);
    throw error;
  }
}

export const getMaintenanceRecommendationsData = async (diuoValue) => {
  if (!diuoValue) {
    throw new Error('diuoValue parameter is required');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getMaintenanceRecommendationsData/${encodeURIComponent(diuoValue)}`,
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message ||
        `La requête a échoué avec le statut ${response.status}`
      );
    }

    return await response.json();

  } catch (error) {
    console.error('Erreur lors de la récupération des données de maintenance:', error);
    throw error;
  }
}

export const getProjectPresentationData = async (diuoValue) => {
  if (!diuoValue) {
    throw new Error('diuoValue parameter is required');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getProjectPresentationData/${encodeURIComponent(diuoValue)}`,
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message ||
        `La requête a échoué avec le statut ${response.status}`
      );
    }

    return await response.json();

  } catch (error) {
    console.error('Erreur lors de la récupération des données des participants et des organisations de protection:', error);
    throw error;
  }
}

export const getOperationDescriptionData = async (ndossier, index, diuoValue) => {
  if (!diuoValue) {
    throw new Error('diuoValue parameter is required');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getOperationDescriptionData/${encodeURIComponent(ndossier)}/${encodeURIComponent(index)}/${encodeURIComponent(diuoValue)}`, requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message ||
        `La requête a échoué avec le statut ${response.status}`
      );
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données des participants et des organisations de protection:', error);
    throw error;
  }
}

export const getTechnicalLotsData = async (diuoValue) => {
  if (!diuoValue) {
    throw new Error('diuoValue parameter is required');
  }

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getTechnicalLotsData/${encodeURIComponent(diuoValue)}`, requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message ||
        `La requête a échoué avec le statut ${response.status}`
      );
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données des participants et des organisations de protection:', error);
    throw error;
  }
}

export const getCompletedWorksData = async (diuoValue) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getCompletedWorksData/${encodeURIComponent(diuoValue)}`, requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || `La requête a échoué avec le statut ${response.status}`
      );
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données des ouvrages exécutés:', error);
    throw error;
  }
}

export const getSelectedSheetsData = async (dossierValue, index, diuoValue) => {
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };

  try {
    const response = await fetch(
      `/api/diuo/getSelectedSheetsData/${encodeURIComponent(dossierValue)}/${encodeURIComponent(index)}/${encodeURIComponent(diuoValue)}`, 
      requestOptions
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        errorData.message || `La requête a échoué avec le statut ${response.status}`
      );
    }

    const data = await response.json();
    console.log("data", data);
    return data;

  } catch (error) {
    console.error('Erreur lors de la récupération des données des feuilles choisies:', error);
    throw error;
  }
};







































