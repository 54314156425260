import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getCompletedWorksData } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { initialCompletedWorks, completedWorksConst } from './Const';
import "../../../Styles/DIUO/Page6.scss";

const DiuoPage6 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [completedWorks, setCompletedWorks] = useState([initialCompletedWorks]);

  const loadData = async () => {
    setIsLoading(true);
    try {
      if (!index || !diuoValue) {
        setCompletedWorks([initialCompletedWorks]);
        return
      }
      const response = await getCompletedWorksData(diuoValue);
      const parsedResponse = response ? JSON.parse(response) : [initialCompletedWorks];
      setCompletedWorks(parsedResponse);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleAddCompletedWork = () => {
    const newCompletedWorks = [...completedWorks, { ...initialCompletedWorks }];
    setCompletedWorks(newCompletedWorks);
  }

  const handleDeleteCompletedWork = (index) => {
    if (completedWorks.length === 1) {
      setCompletedWorks([initialCompletedWorks]);
      return;
    }

    const newCompletedWorks = completedWorks.filter((_, i) => i !== index);
    setCompletedWorks(newCompletedWorks);
  };


  const handleObjectChange = (index, object) => {
    const newCompletedWorks = [...completedWorks];
    if (object === "") {
      newCompletedWorks[index] = { ...completedWorksConst };
      setCompletedWorks(newCompletedWorks);
      return
    }
    const selectedItem = completedWorksConst.find(item => item.object === object);
    newCompletedWorks[index] = selectedItem;
    setCompletedWorks(newCompletedWorks);
  }

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        diuoValue,
        completedWorks: JSON.stringify(completedWorks)
      }),
    };

    try {
      const response = await fetch('/api/diuo/saveCompletedWorks', requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Failed to save technical lots');
      }
    } catch (err) {
      console.error('Error saving technical lots:', err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleGenClick = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let blob = await genPgcPDF(diuoValue, dossierValue, index);
    // if (blob) {
    //   setIsOpen(true);
    //   setFile(blob);
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'pdf');
    // if (response.blob.size > 100) {
    //   setFile(response.blob);
    //   setIsOpen(true);
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'pdf');
    // if (response.blob.type === 'application/pdf') {
    //   setFile(response.blob);
    //   const fileName = response.fileName;
    //   dispatch(changePdfFileName(fileName))
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'word');
    // if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    //   setFile(response.blob);
    //   dispatch(changePdfFileName(response.fileName))
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page5");
  }

  const nextPage = () => {
    history.push("/diuo/page7");
  }

  return (
    <div className="diuo-page6">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
      // file={file}
      // getSourceFile={handleGetWordFile}
      // getPDF={handleGetPdfFile}
      // handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Ouvrages exécutés)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Objet du document</strong></td>
              <td className="custom-cell-label"><strong>Intitulé et Référence du document</strong></td>
              <td className="custom-cell-label"><strong>Par</strong></td>
              <td className="custom-cell-label"><strong>En date du</strong></td>
              <td className="custom-cell-label"><strong>Annexe</strong></td>
              <td></td>
            </tr>
            {completedWorks.map((item, i) => {
              const filteredCompletedWorksConst = completedWorksConst.filter(element => !completedWorks.some(el => el.object === element.object && el !== item));
              return (
                <tr className="custom-cell" key={i}>
                  <td style={{ width: '200px' }}>
                    <select
                      value={item.object || ''}
                      onChange={(e) => handleObjectChange(i, e.target.value)}
                      style={{ width: '180px', textAlign: 'center' }}
                    >
                      <option value={''}></option>
                      {filteredCompletedWorksConst.map((itemFil, indexFil) => (
                        <option value={itemFil.object} key={indexFil}>{itemFil.object}</option>
                      ))}
                    </select>
                  </td>
                  <td style={{ width: '400px' }}>
                    <textarea
                      name="reference"
                      value={item.reference || ''}
                      onChange={(e) => setCompletedWorks(prevState =>
                        prevState.map((item, index) => index === i ? { ...item, reference: e.target.value } : item)
                      )}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <input
                      name="via"
                      value={item.via || ''}
                      onChange={(e) => setCompletedWorks(prevState =>
                        prevState.map((item, index) => index === i ? { ...item, via: e.target.value } : item)
                      )}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <input
                      name="asof"
                      value={item.asof || ''}
                      onChange={(e) => setCompletedWorks(prevState =>
                        prevState.map((item, index) => index === i ? { ...item, asof: e.target.value } : item)
                      )}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <input
                      name="annex"
                      value={item.annex || ''}
                      onChange={(e) => setCompletedWorks(prevState =>
                        prevState.map((item, index) => index === i ? { ...item, annex: e.target.value } : item)
                      )}
                    />
                  </td>
                  <td style={{ width: '50px' }}>
                    <IconButton
                      style={{ marginLeft: '5px' }}
                      aria-label="delete"
                      size="small"
                      className="delete"
                      onClick={() => handleDeleteCompletedWork(i)}
                      disabled={completedWorks.length === 1 && completedWorks.every((item) => JSON.stringify(item) === JSON.stringify(initialCompletedWorks))}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              )
            })}

            {completedWorks.length < 27 && <tr className="add-technical">
              <td>
                <IconButton
                  aria-label="add"
                  style={{ backgroundColor: "transparent", padding: '16px' }}
                  onClick={handleAddCompletedWork}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>}
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          // onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          // onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      {/* <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      /> */}
    </div>
  );
};

export default DiuoPage6;