import React from 'react';
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const SheetOther = ({ sheet, sheetNumber, handleChange, handleFileChange, handleDeleteImage }) => {
  return (
    <div className="sheet-other">
      <table>
        <thead>
          <tr>
            <th colSpan="2"><strong>Fiche n°{sheetNumber} {sheet.title}</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Description</td>
            <td>Localisation</td>
          </tr>
          <tr>
            <td><textarea value={sheet.description} onChange={handleChange('description')} /></td>
            <td><textarea value={sheet.localization} onChange={handleChange('localization')} /></td>
          </tr>
          <tr>
            <td>Nature des interventions</td>
            <td>Accès</td>
          </tr>
          <tr>
            <td><textarea value={sheet.natureOfInterventions} onChange={handleChange('natureOfInterventions')} /></td>
            <td><textarea value={sheet.access} onChange={handleChange('access')} /></td>
          </tr>
          <tr>
            <td>Risques</td>
            <td>Mesures de sécurité</td>
          </tr>
          <tr>
            <td><textarea value={sheet.risks} onChange={handleChange('risks')} /></td>
            <td><textarea value={sheet.securityMeasures} onChange={handleChange('securityMeasures')} /></td>
          </tr>
          <tr>
            <td colSpan="2">Reportage photographique</td>
          </tr>
          <tr>
            <td>
              {sheet.img1 ? (
                <div className="img-item">
                  <IconButton
                    className="item-delete"
                    style={{
                      color: "white",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                    onClick={() => handleDeleteImage('img1')}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                  <img
                    src={URL.createObjectURL(sheet.img1)}
                    alt="Figure 1"
                    style={{ height: "230px", width: "98%" }}
                  />
                  <p>Figure 1</p>
                </div>
              ) : (
                <input type="file" onChange={handleFileChange('img1')} />
              )}
            </td>
            <td>
              {sheet.img2 ? (
                <div className="img-item">
                  <IconButton
                    className="item-delete"
                    style={{
                      color: "white",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                    onClick={() => handleDeleteImage('img2')}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                  <img
                    src={URL.createObjectURL(sheet.img2)}
                    alt="Figure 2"
                    style={{ height: "230px", width: "98%" }}
                  />
                  <p>Figure 2</p>
                </div>
              ) : (
                <input type="file" onChange={handleFileChange('img2')} />
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SheetOther;