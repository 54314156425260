export const siteParticipantsConst = [
  { title: "Maître d'ouvrage", adress: '', representative: '', contact: '' },
  { title: 'Architecte', adress: '', representative: '', contact: '' },
  { title: 'Bureau', adress: '', representative: '', contact: '' },
  { title: 'Contrôle technique de la construction', adress: '', representative: '', contact: '' },
  {
    title: 'Coordonnateur SPS', adress: 'COORDOGEC\n4, rue des Artisans\n93160 - NOISY LE GRAND',
    representative: 'Jean-Marie SAINT-GERMAIN',
    contact: '0143036317 - 0680681482\ncoordogec@coordogec.fr'
  },
]

export const preventionOrganizationsCont = {
  laborInspection: { adress: '', representative: '', contact: '' },
  CRAM: { adress: '', representative: '', contact: '' },
  OPPBTP: { adress: '', representative: '', contact: '' }
}