export const checkboxes = [
  "Éclairage intérieur / extérieur",
  "Gaines techniques",
  "Habilitations électrique",
  "Blocs autonomes d’éclairage de sécurité (B.A.E.S) ou (B.A.E.H)",
  "Travail en hauteur",
  "Travaux de peinture",
  "Occultations",
  "Vérification périodique des extincteurs",
  "VMC + conduite gaz",
  "Terrasse inaccessible",
  "Travaux par point chaud",
  "Portes garage principale + parking",
  "Ravalement des façades + ITE",
  "Couverture / charpente / étanchéité",
  "Chauffage",
  "Pompes de relevage parking",
  "Ascenseur",
  "Colonne sèche",
  "Désenfumage",
  "VRD + espace verts",
  "Détecteur de fumée",
  "Vérification périodique de l'extracteur d'air des parkings",
  "Vérification périodique de l'extracteur d'air"
];