import React from 'react';

const Sheet5Table = ({ sheet, sheetNumber }) => {
  return (
    <div className="sheet-5-table">
      <div className='sheet-5-table-1'>
        <table>
          <thead>
            <tr>
              <th colSpan="2" ><strong>Fiche n°{sheetNumber} {sheet.title}</strong></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan="2" style={{ padding: '15px 0', backgroundColor: '#9b9b9b', fontWeight: 'bold' }}>Description</td>
            </tr>
            <tr>
              <td style={{ textAlign: 'left', paddingTop: '10px' }} colSpan="2">
                Une échelle (norme EN 131) ne peut être qu’un moyen d’accès à un niveau avec les deux mains libres.<br /><br />
                <strong>TROIS NORMES EXISTENT POUR UN <u>POSTE DE TRAVAIL</u>:</strong><br />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className='sheet-5-table-2'>
        <table>
          <thead>
            <tr>
              <th>Hauteur de la plate-forme</th>
              <th>Nombre de marche</th>
              <th>Hauteur de travail</th>
              <th>Type</th>
              <th>Norme</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1.00 m</td>
              <td>4 ou 5</td>
              <td>2.50 / 3.00 m</td>
              <td>P.I.R.L</td>
              <td>93 - 353</td>
            </tr>
            <tr>
              <td>1.15 à 2.50 m</td>
              <td>6 à 12</td>
              <td>1.65 / 4.50 m</td>
              <td>P.I.R</td>
              <td>93 - 352</td>
            </tr>
            <tr>
              <td>+ de 2.50 m</td>
              <td>-</td>
              <td>+ de 4.00 m</td>
              <td>Échafaudage roulant</td>
              <td>NF HD 1004</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div style={{ marginLeft: '20px' }}>
        Les “Gazelles” avec des plates-formes allongées de 1.50 m peuvent être aussi normalisées.<br />
        Les entreprises devront être dotées de plates-formes ou d'échafaudages réglementaires.<br />
        <strong>HAUTEURS SOUS PLAFOND: Variable selon localisation de 2.40m à 2.90m</strong><br />
        (voir plan architecte)
      </div>
    </div>
  );
};

export default Sheet5Table;