import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getSelectedSheetsData } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import TableComponent from './TableComponent';
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { checkboxes } from './Const';
import "../../../Styles/DIUO/Page7.scss";

const DiuoPage7 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [selectedSheets, setSelectedSheets] = useState({});

  const loadData = async () => {
    setIsLoading(true);
    try {
      if (!index, !diuoValue) {
        setSelectedSheets({});
        return
      }
      const response = await getSelectedSheetsData(dossierValue, index, diuoValue);
      setSelectedSheets(response.selectedSheets);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleCheckboxChange = (index) => {
    setSelectedSheets((prevSelected) => {
      const newSelected = { ...prevSelected };
      const key = `sheet${index + 1}`;
      if (newSelected[key]) {
        delete newSelected[key];
      } else {
        newSelected[key] = {
          title: checkboxes[index],
          description: "",
          localization: "",
          natureOfInterventions: "",
          access: "",
          risks: "",
          securityMeasures: "",
          img1: null,
          img2: null
        };
      }
      return newSelected;
    });
  };

  const handleInputChange = (key, field, value) => {
    setSelectedSheets((prevSelected) => {
      const newSelected = { ...prevSelected };
      newSelected[key][field] = value;
      return newSelected;
    });
  };

  const sortedKeys = Object.keys(selectedSheets).sort((a, b) => {
    const numA = parseInt(a.replace('sheet', ''), 10);
    const numB = parseInt(b.replace('sheet', ''), 10);
    return numA - numB;
  });

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('diuoValue', diuoValue);

    Object.keys(selectedSheets).forEach((sheetKey) => {
      const sheet = selectedSheets[sheetKey];

      // Ajouter les informations textuelles de la feuille
      formData.append(`${sheetKey}_title`, sheet.title);
      formData.append(`${sheetKey}_description`, sheet.description);
      formData.append(`${sheetKey}_localization`, sheet.localization);
      formData.append(`${sheetKey}_natureOfInterventions`, sheet.natureOfInterventions);
      formData.append(`${sheetKey}_access`, sheet.access);
      formData.append(`${sheetKey}_risks`, sheet.risks);
      formData.append(`${sheetKey}_securityMeasures`, sheet.securityMeasures);

      // Ajouter les images de la feuille
      Object.keys(sheet).forEach((key) => {
        if (key.startsWith('img') && sheet[key] instanceof File) {
          formData.append(`${sheetKey}_${key}`, sheet[key]);
        }
      });
    });

    try {
      const response = await fetch('/api/diuo/saveSelectedSheets', {
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to upload images');
      }
    } catch (err) {
      console.error('Error saving technical lots:', err.message);
    } finally {
      setIsLoading(false);
    }
  };


  const handleGenClick = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let blob = await genPgcPDF(diuoValue, dossierValue, index);
    // if (blob) {
    //   setIsOpen(true);
    //   setFile(blob);
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'pdf');
    // if (response.blob.size > 100) {
    //   setFile(response.blob);
    //   setIsOpen(true);
    //   window.scrollTo({
    //     top: 0,
    //     behavior: "smooth",
    //   });
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'pdf');
    // if (response.blob.type === 'application/pdf') {
    //   setFile(response.blob);
    //   const fileName = response.fileName;
    //   dispatch(changePdfFileName(fileName))
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    // setDisableButton(true);
    // setFile(null);
    // let response = await getPgcFile(dossierValue, index, 'word');
    // if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
    //   setFile(response.blob);
    //   dispatch(changePdfFileName(response.fileName))
    // }
    // setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page6");
  }

  const nextPage = () => {
    history.push("/diuo/page8");
  }

  console.log("selectedSheets", selectedSheets);

  return (
    <div className="diuo-page7">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
      // file={file}
      // getSourceFile={handleGetWordFile}
      // getPDF={handleGetPdfFile}
      // handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Fiches techniques)</h1>
      <NumDossDIUO />
      <div className="container">
        <div className="checkbox-grid">
          {checkboxes.map((label, index) => (
            <div key={index} className={index % 2 === 0 ? "checkbox-item-left" : "checkbox-item-right"}>
              <label>
                <input
                  type="checkbox"
                  checked={selectedSheets[`sheet${index + 1}`] !== undefined}
                  onChange={() => handleCheckboxChange(index)}
                />
                &nbsp;Fiche n°{index + 1} {label}
              </label>
            </div>
          ))}
        </div>
        <div className="table-container">
          {sortedKeys.map((key) => (
            <TableComponent
              key={key}
              sheet={selectedSheets[key]}
              sheetNumber={parseInt(key.replace('sheet', ''))}
              onInputChange={(field, value) => handleInputChange(key, field, value)}
            />
          ))}
        </div>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          // onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          // onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          // onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      {/* <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      /> */}
    </div>
  );
};

export default DiuoPage7;